import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import useStore from "../../../store";
import { Button, Col, Form, Modal, Row, Tabs } from "antd";
import { GrDocumentExcel } from "react-icons/gr";
import { CheckOutlined, CloseOutlined, EyeOutlined } from "@ant-design/icons";
import { AgGridColumn } from "ag-grid-react";
import { observer } from "mobx-react";
import RoleList from "../../../config/RoleList";
import UserLoanDetails from "./UserLoanDetails";
import { Link, useHistory } from "react-router-dom";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { RejectReasonRequest } from "../../../requests/WelfareRequestRequest";
import moment from "moment";
import { Cash_Module, displayFormats } from "../../../config/Global";
import DateComparator from "../../../utils/DateComparator";
import ExportPopupComponent from "../../../utils/ExportPopupComponent";
import amount from "../../../assets/images/amount-icon.svg"
import Calendar from "../../../assets/images/Calendar.svg"
import profile_icon from "../../../assets/images/profile-icon.svg";
import duration from "../../../assets/images/duration.svg";
import PaymentIcon from "../../../assets/images/money.svg";
import PayRequestedLoan from "./PayRequestedLoan";
import { LoanRequest } from "../../../requests/LoanRequest";

const ViewGroupLoans: React.FC = observer(() => {
	const [rejectReasonForm] = Form.useForm();
	const history = useHistory();
	const { t } = useTranslation();
	const { TabPane } = Tabs;
	const [form] = Form.useForm();
	const [form2] = Form.useForm();
	const [showUserLoanDetails, showUserLoanDetailsModel] = useState(false);
	const [userRoleInThisGroup, setUserRoleInThisGroup] = useState<any>();
	const [saving, setSaving] = useState<boolean>(false);
	const [rejectReasonModel, setRejectReasonModel] = useState(false);
	const [rejectedId, setRejectedId] = useState<any>(0);

	const [exportSaving, setExportSaving] = useState<boolean>(false);
	const [exportPopup, showExportPopup] = useState<boolean>(false);
	const [activeTabName, setActiveTabName] = useState<string>("LoanRequest");

	const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
	const [updateConfirmationId, setUpdateConfirmationId] = useState(null);
	const [width, setWidth] = useState(window.innerWidth);
	const [openPaymentModeModal, setOpenPaymentModeModal] = useState(false)
	const [loanData, setLoanData] = useState<any>()
	const [openPayCashModal, setOpenPayCashModal] = useState(false)
	const [displayOtp, setDisplayOtp] = useState(false)

	const {
		ROOT: { AssignErrorToInput },
		AUTH: { selectedGroup, user },
		COMMON: { paymentListData },
		GENERATE_OTP: { createGeneratedOtp },
		LOAN: { groupUserLoanRequestListGrid, setupGroupUserLoanRequestListGrid,
			userLoanDisbursementListGrid, setupUserLoanDisbursementListGrid,
			approveUserLoanRequest, getUserLoanRequestDetails, userLoanRequestDetails, setUserLoanRequestDetails,
			exportGroupUserLoanRequestList, exportUserLoanDisbursementList, changePaymentMode, payRequestedLoan },
	} = useStore();

	const closeConfirmationPopup = () => {
		setUpdateConfirmationId(null);
		setConfirmationModal(false);
	};

	const openConfirmationPopup = (confirmationId: any) => {
		setUpdateConfirmationId(confirmationId);
		setConfirmationModal(true);
	};

	const handleSubmitConfirmation = () => {
		approveUserLoanRequest({ user_loan_id: updateConfirmationId, status: 1 }).finally(() => {
			closeConfirmationPopup();
		});
	};

	const statusRenderer = (params: any) => {
		if (userRoleInThisGroup && ((userRoleInThisGroup == RoleList.PRESIDENT && params.data?.president_approve_status == 0) || (userRoleInThisGroup == RoleList.SECRETARY && params.data?.secretary_approve_status == 0) || (userRoleInThisGroup == RoleList.ACCOUNTANT && params.data?.accountant_approve_status == 0))) {
			return (
				<div>
					<Button
						type="default"
						icon={<CheckOutlined />}
						style={{ color: "green" }}
						onClick={() => openConfirmationPopup(params.data?.id)}
					/>
					<Button
						type="default"
						icon={<CloseOutlined />}
						style={{ color: "red" }}
						onClick={() => openRejectReasonModel(params.data?.id)}
					/>
				</div>
			);
		} else if (userRoleInThisGroup && ((userRoleInThisGroup == RoleList.PRESIDENT && params.data?.president_approve_status == 1) || (userRoleInThisGroup == RoleList.SECRETARY && params.data?.secretary_approve_status == 1) || (userRoleInThisGroup == RoleList.ACCOUNTANT && params.data?.accountant_approve_status == 1))) {
			return (
				<div>
					<Button
						type="default"
						icon={<CheckOutlined />}
						style={{ color: "green" }}
					/>
				</div>
			);
		} else {
			return (
				<div>
					<Button
						type="default"
						icon={<CloseOutlined />}
						style={{ color: "red" }}
					/>
				</div>
			);
		}
	};

	const detailUserLoanRequestRenderer = (params: any) => {
		return (
			<div>
				<Button
					type="default"
					icon={<EyeOutlined />}
					style={{ color: "green" }}
					onClick={() => displayUserLoanDetails(params.data?.id)}
				/>
			</div>
		);
	};
	const paymentModeRenderer = (params: any) => {
		return (
			(params.data?.president_approve_status == 0) && <div className="action-column">
				<Button
					type="default"
					style={{ color: "green" }}
					onClick={() => displayPaymentModeDetails(params.data)}
				>{t("CHANGE_PAYMENT_MODE")}</Button>
			</div>
		);
	};
	const payRequestedLoanRenderer = (params: any) => {
		return (
			(params.data.payment_mode == 0 && params.data?.president_approve_status == 1 && params.data?.secretary_approve_status == 1 && params.data?.accountant_approve_status == 1) && <div className="action-column">
				<Button
					type="default"
					style={{ color: "green" }}
					onClick={() => displayPayCashDetails(params.data)}
				>{t("PAY_REQUESTED_LOAN")}</Button>
			</div>
		);
	};
	const payCashInstallmentRenderer = (params: any) => {
		return (
			(params.data.loan_repayment_status == 0) && (params.data.is_paid == 1) && <div className="action-column">
				<Button
					type="default"
					style={{ color: "green" }}
					onClick={() => history.push(`/view-group-loans/${params.data.id}/installments`)}
				>{t("PAY_INSTALLMENT")}</Button>
			</div>
		);
	};

	const displayUserLoanDetails = (id: number) => {
		getUserLoanRequestDetails(id);
		showUserLoanDetailsModel(true);
	};
	const displayPaymentModeDetails = (data: any) => {
		setLoanData(data)
		form.setFieldsValue({
			username: data?.user_name,
			description: data?.description,
			amount: data?.loan_amount,
			duration: data?.number_of_installments,
			preference: data?.payment_mode
		})
		setOpenPaymentModeModal(true);
	};
	const displayPayCashDetails = (data: any) => {
		setLoanData(data)
		setOpenPayCashModal(true);
	};

	useEffect(() => {
		selectedGroup && user?.groups.forEach((element: any) => {
			if (element?.id == selectedGroup) {
				setUserRoleInThisGroup(element?.role?.name ?? "");
			}
		});
	}, [user, selectedGroup]);

	const handleModalSubmit = (data: any) => {
		setSaving(true);
		approveUserLoanRequest({ user_loan_id: rejectedId, status: 2, reject_reason: data.reject_reason })
			.then(() => {
				rejectReasonForm.resetFields();
				closeRejectReasonModel();
			})
			.catch((e: ErrorProps) => {
				AssignErrorToInput(rejectReasonForm, e?.data.errors);
				setSaving(false);
			});
	};

	const openRejectReasonModel = (id: any) => {
		setRejectedId(id);
		setRejectReasonModel(true);
	}

	const closeRejectReasonModel = () => {
		setRejectedId(0);
		setRejectReasonModel(false);
		setSaving(false);
	}

	const exportGroupUserLoanRequest = (type: string) => {
		setExportSaving(true);
		if (activeTabName == 'LoanRequest') {
			exportGroupUserLoanRequestList(type).finally(() => {
				setExportSaving(false);
				showExportPopup(false);
			});
		} else {
			exportUserLoanDisbursementList(type).finally(() => {
				setExportSaving(false);
				showExportPopup(false);
			});
		}
	}

	const setGroupLoanType = (key: any) => {
		setActiveTabName(key);
		if (key == 'LoanRequest') {
			groupUserLoanRequestListGrid && setupGroupUserLoanRequestListGrid(groupUserLoanRequestListGrid);
		}
		if (key == 'LoanDisbursed') {
			userLoanDisbursementListGrid && setupUserLoanDisbursementListGrid(userLoanDisbursementListGrid);
		}
	}

	const updateDimensions = () => {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);


	const handleChangePaymentModeSubmit = (data: any) => {
		changePaymentMode(loanData.id, { preference: data?.preference }).then(() => {
			setOpenPaymentModeModal(false)
			form.resetFields();
		}).catch((e: any) => {
			setOpenPaymentModeModal(false)
			const errors = e?.errors || e?.data.errors;
			AssignErrorToInput(form, errors);
		})
	}
	const handleGenerateOtp = () => {
		const payload = { id: loanData?.id, type: 2 }
		createGeneratedOtp(payload).then(() => {
			setDisplayOtp(true)
		}).catch((e: any) => {
			setDisplayOtp(false)
			const errors = e?.errors || e?.data.errors;
			AssignErrorToInput(form2, errors);
		})
	}
	const handlePayRequestedLoan = (data: any) => {
		const payload = { user_loan_id: loanData?.id, ...data }
		payRequestedLoan(payload).then(() => {
			setOpenPayCashModal(false)
			form2.resetFields()
			setDisplayOtp(false)
		}).catch((e: any) => {
			const errors = e?.errors || e?.data.errors;
			AssignErrorToInput(form2, errors);
		})
	}
	return (
		<>
			<div className="member-details-section">
				<div className="main grid-class">
					<div className="heading-title-wrap">
						<h3>{t('VIEW_GROUP_LOAN')}</h3>
						<div className="btn-wrap">
							<div className="text-center btn btn-primary btn-yellow">
								<Link to={"/my-loans"} type="primary">
									{t("BACK")}
								</Link>
							</div>
							{((groupUserLoanRequestListGrid && groupUserLoanRequestListGrid?.api && groupUserLoanRequestListGrid?.api.getDisplayedRowCount() > 0) || (userLoanDisbursementListGrid && userLoanDisbursementListGrid?.api && userLoanDisbursementListGrid?.api.getDisplayedRowCount() > 0)) && <Button className="btn-yellow" type="primary" onClick={() => showExportPopup(true)}>
								{t("GENERATE_REPORT")} <GrDocumentExcel />
							</Button>}

							<ExportPopupComponent
								callbackExportFunction={exportGroupUserLoanRequest}
								setVisible={showExportPopup}
								visible={exportPopup}
								saving={exportSaving}
							/>
						</div>
					</div>
					<div className="sub-custom-tabs">
						{user && userRoleInThisGroup && <Tabs defaultActiveKey="1" style={{ marginBottom: 32 }} onChange={setGroupLoanType}>
							<TabPane tab={t('LOAN_REQUEST')} key="LoanRequest">
								<AgGridWrapper
									type="serverSide"
									onGridReady={setupGroupUserLoanRequestListGrid}
									rowSelection={"multiple"}
									className="member-table"
									immutableData={true}
									frameworkComponents={{ statusRenderer, payRequestedLoanRenderer, detailUserLoanRequestRenderer, paymentModeRenderer }}
									overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
								>
									<AgGridColumn field="id" headerName={"# " + t('ID')} width={100} pinned={width > 769 ? "left" : ""} filter="agNumberColumnFilter" />
									<AgGridColumn field="description" headerName={t('DESCRIPTION')} />
									<AgGridColumn field="group_name" headerName={t('GROUP_NAME')} />
									<AgGridColumn field="user_name" headerName={t('MEMBER_NAME')} />
									<AgGridColumn field="loan_amount" headerName={t('AMOUNT')} filter={"agNumberColumnFilter"} />
									<AgGridColumn field="number_of_installments" headerName={t('NUMBER_OF_INSTALLMENTS')} filter={"agNumberColumnFilter"} />
									{Cash_Module && <AgGridColumn field="loan_payment_mode" headerName={t('PAYMENT_MODE')} filter={"agTextColumnFilter"} />}
									<AgGridColumn
										field="display_approved_status"
										headerName={t('STATUS')}
										cellRenderer="statusRenderer"
										width={120}
										pinned={width > 769 ? "right" : ""}
										sortable={false}
										filter={false}
									/>


									<AgGridColumn
										field="id"
										headerName={t('PAYMENT_MODE')}
										cellRenderer="paymentModeRenderer"
										width={200}
										pinned={width > 769 ? "right" : ""}
										sortable={false}
										filter={false}
										hide={!(userRoleInThisGroup && userRoleInThisGroup == RoleList.PRESIDENT && Cash_Module)}
									/>
									<AgGridColumn
										field="id"
										headerName={t('PAY_REQUESTED_LOAN')}
										cellRenderer="payRequestedLoanRenderer"
										width={170}
										pinned={width > 769 ? "right" : ""}
										sortable={false}
										filter={false}
										hide={!(userRoleInThisGroup && userRoleInThisGroup == RoleList.PRESIDENT && Cash_Module)}

									/>


									<AgGridColumn
										field="id"
										headerName={t('DETAIL')}
										cellRenderer="detailUserLoanRequestRenderer"
										width={80}
										pinned={width > 769 ? "right" : ""}
										sortable={false}
										filter={false}

									/>


								</AgGridWrapper>
							</TabPane>
							<TabPane tab={t('LOAN_DISBURSED')} key="LoanDisbursed">
								<AgGridWrapper
									type="serverSide"
									onGridReady={setupUserLoanDisbursementListGrid}
									rowSelection={"multiple"}
									className="member-table"
									immutableData={true}
									overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
									frameworkComponents={{ payCashInstallmentRenderer }}
								>
									<AgGridColumn field="id" headerName={"# " + t('ID')} width={100} pinned={width > 769 ? "left" : ""} filter="agNumberColumnFilter" />
									<AgGridColumn field="user_name" headerName={t('MEMBER_NAME')} />
									<AgGridColumn field="description" headerName={t('DESCRIPTION')} />
									<AgGridColumn
										field="sanctioned_date"
										headerName={t('SANCTIONED_DATE')}
										filter={"agDateColumnFilter"}
										valueFormatter={(data: any) =>
											data?.data.sanctioned_date && moment.utc(data?.data.sanctioned_date).local().format(displayFormats.DATE_FORMAT)
										}
										filterParams={{
											comparator: DateComparator,
										}}
									/>
									<AgGridColumn field="number_of_installments" headerName={t('DURATION')} filter={"agNumberColumnFilter"} />
									<AgGridColumn field="loan_amount" headerName={t('AMOUNT')} filter={"agNumberColumnFilter"} />
									<AgGridColumn field="interest_rate" headerName={t("INTEREST_RATE")} filter={"agNumberColumnFilter"}/>
									<AgGridColumn field="repay_amount" headerName={t("REPAY_AMOUNT")} filter={"agNumberColumnFilter"}/>
									{Cash_Module && <AgGridColumn field="loan_payment_mode" headerName={t('PAYMENT_MODE')} filter={"agTextColumnFilter"} />}
									{Cash_Module && <AgGridColumn
										field="id"
										headerName={t('PAY_CASH_INSTALLMENT')}
										cellRenderer="payCashInstallmentRenderer"
										width={220}
										pinned={width > 769 ? "right" : ""}
										sortable={false}
										filter={false}

									/>}
								</AgGridWrapper>
							</TabPane>
						</Tabs>}


						<Modal
							title={t("REASON")}
							centered
							visible={rejectReasonModel}
							onOk={() => setRejectReasonModel(false)}
							onCancel={() => setRejectReasonModel(false)}
							afterClose={() => closeRejectReasonModel()}
							className="custom-popup"
							footer={null}
						>
							<FormBox form={rejectReasonForm} onFinish={handleModalSubmit}>
								<Row gutter={[20, 20]}>
									<Col span={24}>
										<div className="field">
											<small>{t("REASON")}</small>
											<InputBox.TextArea name="reject_reason" placeholder={t("REASON")}
												rules={RejectReasonRequest(t).reject_reason} />
										</div>
									</Col>
								</Row>
								<div className="btn">
									<Button loading={saving} className="btn-secondary" htmlType="submit">
										{t("SAVE")}
									</Button>
									<Button className="btn-danger" onClick={closeRejectReasonModel}>{t("CANCEL")}</Button>
								</div>
							</FormBox>
						</Modal>

						{/* change payment mode modal */}
						<Modal
							centered
							visible={openPaymentModeModal}
							onOk={() => setOpenPaymentModeModal(false)}
							onCancel={() => {
								setOpenPaymentModeModal(false);
								form.resetFields();
							}}
							className="custom-popup info-popup generate-otp"
							title={t("CHANGE_PAYMENT_MODE")}
							footer={null}
							width={10}
						>

							<div className="register-section section-wrap">
								<div className="main">
									<FormBox onFinish={handleChangePaymentModeSubmit} form={form}>
										<Row>
											<Col span={12}>
												<div className="field">
													<i className="iconAlignment">
														<img src={profile_icon} alt="Profile" />
													</i>
													<InputBox.Text
														name={"username"}
														label={t("USER_NAME")}
														disabled
													/>
												</div>
												<div className="field">
													<i className="iconAlignment">
														<img src={duration} alt="Profile" />
													</i>
													<InputBox.Text
														name={"duration"}
														label={t("DURATION")}
														disabled />
												</div>
												<div className="field">
													<i className="iconAlignment">
														<img src={PaymentIcon} alt="Profile" />
													</i>
													<InputBox.Select
														name={"preference"}
														label={t("WHAT_IS_YOUR_PREFERENCE")}
														placeholder={t("PREFERENCE")}
														options={{
															list: paymentListData,
															valueKey: "id",
															textKey: "name",
														}}
														rules={LoanRequest(t).preference}
													/>
												</div>
											</Col>
											<Col span={12}>
												<div className="field">
													<i className="iconAlignment">
														<img src={amount} alt="Amount" />
													</i>
													<InputBox.Text
														name={"amount"}
														label={t("AMOUNT")}
														disabled />
												</div>
												<div className="field">
													<i className="iconAlignment">
														<img src={Calendar} alt="Profile" />
													</i>
													<InputBox.TextArea
														name={"description"}
														label={t("DESCRIPTION")}
														disabled />
												</div>
											</Col>
										</Row>
										<Row>
										</Row><br />
										<Row>
											<Col span={24}>
												<div className="btn btn-wrap">
													<Button
														type="primary"
														htmlType="submit"
														className="btn btn-primary"
													>
														{t("SAVE")}
													</Button>
													<Button
														type="primary"
														className="btn btn-danger"
														onClick={() => {
															setOpenPaymentModeModal(false)
															form.resetFields();
														}}
													>
														{t("CANCEL")}
													</Button>
												</div>
											</Col>
										</Row>
									</FormBox>
								</div>
							</div>
						</Modal>

						{/* pay requested loan modal */}
						<PayRequestedLoan
							openPayCashModal={openPayCashModal}
							setOpenPayCashModal={setOpenPayCashModal}
							activeTabName={activeTabName}
							data={loanData}
							handleGenerateOtp={handleGenerateOtp}
							handleSubmit={handlePayRequestedLoan}
							form={form2}
							displayOtp={displayOtp}
							setDisplayOtp={setDisplayOtp}
						/>
					</div>
				</div>
			</div>
			<UserLoanDetails
				showUserLoanDetails={showUserLoanDetails}
				showUserLoanDetailsModel={showUserLoanDetailsModel}
				userLoanRequestDetails={userLoanRequestDetails}
				setUserLoanRequestDetails={setUserLoanRequestDetails}
				userLoanRequestType={"loan-detail"}
			/>

			<Modal
				centered
				visible={confirmationModal}
				onOk={closeConfirmationPopup}
				onCancel={closeConfirmationPopup}
				className="custom-popup info-popup"
				footer={null}
			>
				<div className="info-text">
					<p>
						{t('ACCEPT_THE_REQUEST')}
					</p>
				</div>
				<div className="btn">
					<Button className="btn-secondary" onClick={handleSubmitConfirmation}>{t("ACCEPT")}</Button>
					<Button className="btn-grey" onClick={closeConfirmationPopup}>{t("CANCEL")} </Button>
				</div>
			</Modal>
		</>
	);
});

export default ViewGroupLoans;
