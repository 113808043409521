import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import RootStore from "../RootStore/RootStore";

export default class DeleteAccountStore {
	private rootStore: RootStore;
	public rowData?:any;

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

    //Setter Function
	private setRowData = (value: any) => {
		this.rowData = value;
	};
	
    public sendOtp = (payload:any): Promise<any> => {
		return axios
			.post(API_URL.DELETE_ACCOUNT.OTP,payload)
			.then(({ data }) => {
				return data.data?.rows;
			});
	};
    public verifyDeleteAccount = (payload:any): Promise<any> => {
		return axios
			.post(API_URL.DELETE_ACCOUNT.VERIFY_DELETE_ACCOUNT,payload)
			.then(({ data }) => {
				return data.data?.rows;
			});
	};

}
