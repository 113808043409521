import { isPossiblePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";


export const DeleteAccountRequest = (t: any): RequestProps => ({
	username: [
		// { required: true, message: Message(t).username() },
		{ required: true, message: Message(t).required(t("EMAIL_ID")) },
		{ type: "email", message: Message(t).type.email(t("EMAIL_ID")) },
	],
	otp: [
		{ required: true, message: Message(t).required(t("OTP")) },
	],
	phone_number: [
		{ required: true, message: Message(t).required(t("PHONE_NUMBER")) },
		({ getFieldValue }) => ({
			validator() {
				const phone_number = getFieldValue("phone_number");

				if (
					phone_number &&
					(!isPossiblePhoneNumber(phone_number) || !isValidPhoneNumber(phone_number))
				) {
					return Promise.reject(t("PLEASE_ENTER_VALID_FIELD_NAME", { fieldName: t("PHONE_NUMBER") }));
				}
				return Promise.resolve();
			},
		}),
	],
})