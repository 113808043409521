import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Form, Button } from "antd";
import Lock from "../../../assets/images/Lock.svg";
import amount from "../../../assets/images/amount-icon.svg";
import user_light from "../../../assets/images/user-icon-light.svg";
import useStore from "../../../store";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { LoanRequest } from "../../../requests/LoanRequest";
import { observer } from "mobx-react";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import PaymentIcon from "../../../assets/images/money.svg";
import { Cash_Module } from "../../../config/Global";

const AddLoans: React.FC = observer(() => {
	const [form] = Form.useForm();
	const {
		ROOT: { AssignErrorToInput },
		AUTH: { user, selectedGroupDetails },
		LOAN: { createMyLoan },
		COMMON: { paymentListData },
	} = useStore();
	const history = useHistory();
	const { t } = useTranslation();
	const [saving, setSaving] = useState<boolean>(false);
	const [loanAmt, setLoanAmt] = useState<any>()

	const handleSubmit = (data: any) => {
		delete data.actual_loan_amount
		setSaving(true);
		createMyLoan(data).then(() => {
			history.replace("/my-loans");
		}).catch((e: ErrorProps) => {
			const errors = e?.errors || e?.data?.errors;
			errors && AssignErrorToInput(form, errors);
			setSaving(false);
		});
	};

	useEffect(() => {
		selectedGroupDetails && form.setFieldsValue({ max_borrowing_limit: selectedGroupDetails.borrowing_limit });
	}, [selectedGroupDetails]);

	const listRoute = () => {
		history.push('/my-loans');
	}

	const validateMax = (maxValue: any) => (_: any, value: any) => {
		if (parseFloat(value) >= parseFloat(maxValue)) {
			return Promise.reject(
				t("INITIAL_DEPOSIT_AMOUNT_VALIDATION_MSG")
			);
		} else if (maxValue == 0 || maxValue == undefined || maxValue == "") {
			return Promise.reject(
				t("PLEASE_ENTER_LOAN_AMOUNT_FIRST_THEN_YOU_CAN_ADD_INITIAL_DEPOSIT_AMOUNT")
			);
		}
		return Promise.resolve();
	};
	return (
		<>
			<div className="register-section section-wrap">
				<div className="main">
					<h3>{t("LOAN")}</h3>
					<FormBox form={form} onFinish={handleSubmit}>
						<InputBox.Text name="max_borrowing_limit" hidden={true} />
						<Row>
							<Col span={12}>
								<div className="field">
									<i>
										<img src={user_light} alt="Profile" />
									</i>
									{user && (
										<InputBox.Text
											disabled={true}
											placeholder={user.firstname + " " + user.lastname}
											label={t('USER_NAME')}
										/>
									)}
								</div>
								<div className="field">
									<i>
										<img src={Lock} alt="Profile" />
									</i>
									<InputBox.TextArea
										name="description"
										rules={LoanRequest(t).description}
										placeholder={t("DESCRIPTION")}
										label={t('DESCRIPTION')}
									/>
								</div>
								<div className="field">
									<i>
										<img src={user_light} alt="Profile" />
									</i>
									<InputBox.Text
										name="number_of_installments"
										placeholder={t("DURATION")}
										label={t("DURATION")}
										rules={LoanRequest(t).number_of_installments}
									/>
								</div>
							</Col>
							<Col span={12}>
								{selectedGroupDetails && <div className="field">
									<i>
										<img src={amount} alt="Amount" />
									</i>
									<InputBox.Text
										name="loan_amount"
										placeholder={t("AMOUNT")}
										label={t("AMOUNT") + " (" + t("MAXIMUM_AMOUNT_OF_LOAN_AS_AMOUNT_CURRENCY", { amount: selectedGroupDetails.borrowing_limit, currency: selectedGroupDetails.currency.name }) + ")"}
										rules={LoanRequest(t).loan_amount}
										onChange={(e) => {
											setLoanAmt(e.target.value)
											const amt = form.getFieldValue("initial_deposit_amount") ?? 0
											const actual_amt = e.target.value == "" ? 0 : parseFloat(e.target.value) - parseFloat(amt)
											form.setFieldsValue({ actual_loan_amount: actual_amt, initial_deposit_amount: 0 })
											form.setFields([
												{
													name: 'initial_deposit_amount',
													errors: [],
												},
											])
										}}
									/>
								</div>}
								<div className="field">
									<i>
										<img src={amount} alt="Amount" />
									</i>
									<InputBox.Text
										name="initial_deposit_amount"
										placeholder={t("INITIAL_DEPOSIT_AMOUNT")}
										label={t("INITIAL_DEPOSIT_AMOUNT")}
										initialValue={0}
										onChange={(e) => {
											const amt = form.getFieldValue("loan_amount") ?? 0
											setLoanAmt(form.getFieldValue("loan_amount"))
											const actual_amt = parseFloat(amt) - (e.target.value == "" ? amt : parseFloat(e.target.value))
											if (parseFloat(form.getFieldValue("loan_amount"))>0) {
												form.setFieldsValue({ actual_loan_amount: actual_amt })
											}
										}}
										rules={[
											{ validator: validateMax(loanAmt) },
										]}
									/>
								</div>
								<div className="field">
									<i>
										<img src={amount} alt="Amount" />
									</i>
									<InputBox.Text
										name="actual_loan_amount"
										placeholder={t("ACTUAL_LOAN_AMOUNT")}
										label={t("ACTUAL_LOAN_AMOUNT")}
										disabled
									/>
								</div>
								{Cash_Module &&
									<div className="field">
										<i className="iconAlignment">
											<img src={PaymentIcon} alt="Profile" />
										</i>
										<InputBox.Select label={t("WHAT_IS_YOUR_PREFERENCE")}
											name={"preference"}
											placeholder={t("PREFERENCE")}
											options={{
												list: paymentListData,
												valueKey: "id",
												textKey: "name",
											}}
											rules={LoanRequest(t).preference}
										/>
									</div>}
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<div className="btn btn-wrap">
									<Button
										type="primary"
										htmlType="submit"
										className="btn btn-primary"
										loading={saving}
									>
										{t("SAVE")}
									</Button>
									<Button
										type="primary"
										className="btn btn-danger"
										onClick={listRoute}
									>
										{t("CANCEL")}
									</Button>
								</div>
							</Col>
						</Row>
					</FormBox>
				</div>
			</div>
		</>
	);
});

export default AddLoans;
