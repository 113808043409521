import React, { useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import logo from "../../../assets/images/Earziki_Logo.svg";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { Button, Form } from "antd";
import { loginWithList } from "../../../config/Global";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import Email_Icon from "../../../assets/images/Email-Icon.svg";
import calling from "../../../assets/images/calling-header.svg";
import CheckMark from "../../../assets/images/check-mark-circle.svg";
import useStore from "../../../store";
import OtpInput from "react-otp-input";
import { DeleteAccountRequest } from "../../../requests/DeleteAccountRequest"


const DeleteAccount: React.FC = observer(() => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const [deleteAccountWith, setDeleteAccountWith] = useState(1)
	const [phoneNo, setPhoneNo] = useState<any>()
	const [saving, setSaving] = useState<boolean>(false);
	const [displayOtp, setDisplayOtp] = useState(false)
	const { DELETE_ACCOUNT: { sendOtp, verifyDeleteAccount }, ROOT: { AssignErrorToInput }, } = useStore();

	const setPhone = (val: any) => {
		const phoneDetails: any = val ? parsePhoneNumber(val) : "";
		setPhoneNo(phoneDetails);
	};

	const handleSubmit = () => {
		const payload = {
			username: deleteAccountWith == 1 ? form.getFieldValue("username") : phoneNo?.nationalNumber,
			country_calling_code: deleteAccountWith == 1 ? undefined : "+" + phoneNo?.countryCallingCode
		}
		form.validateFields().then(() => {
			sendOtp(payload).then(() => {
				setDisplayOtp(true)
				form.setFieldsValue({ otp: "" })
			}).catch((e: any) => {
				AssignErrorToInput(form, e);
			})
		})
	}

	const handleDeleteAccountSubmit = (data: any) => {
		setSaving(true)
		const payload = {
			otp: data?.otp,
			username: deleteAccountWith == 1 ? data?.username : phoneNo?.nationalNumber,
		}
		displayOtp && verifyDeleteAccount(payload).then(() => {
			setSaving(false)
			setPhoneNo("")
			setDisplayOtp(false)
			setDeleteAccountWith(1)
			form.resetFields()

		}).catch((e: any) => {
			console.log(e)
			setSaving(false)
		})
	}
	return (
		<section className="delete-account-container">

			<div className="delete-account-wrapper">

				<div className="logo__wrapper">
					<a href="/" title={"VSLA"}>
						<img src={logo} alt={"VSLA"} />
					</a>
				</div>
				<div className="delete-account-content member-details-section text">
					<div className="main grid-class">

						<h4>{t("DELETE_YOUR_ACCOUNT")}</h4>
						<FormBox form={form} onFinish={handleDeleteAccountSubmit}>
							<div className="field checkbox-container">
								<InputBox.Radio
									options={{
										list: loginWithList(t),
										valueKey: "id",
										textKey: "value"
									}}
									name="delete_account"
									initialValue={1}

									onChange={(e: any) => {
										form.setFieldsValue({ username: "", phone_number: "", otp: "" })
										setDisplayOtp(false)
										if (e.target.value == 1) {
											setDeleteAccountWith(1)
										} else {
											setDeleteAccountWith(2)
										}
									}}
								/>
							</div>
							<div className="field">
								{
									deleteAccountWith == 1 ? <><i className="email-icon">
										<img src={deleteAccountWith == 1 ? Email_Icon : calling} alt="Profile" />
									</i>
										<InputBox.Text
											name="username"
											placeholder={t('EMAIL_ID')}
											size="large"
											label={t('EMAIL_ID')}
											className={displayOtp ? 'disabled-email-field delete-email-input' : 'delete-email-input'}
											disabled={displayOtp}
											rules={DeleteAccountRequest(t).username}
										/></> : <>
										<Form.Item
											name="phone_number"
											label={t("PHONE_NUMBER")}
											className="phoneInputContent"
											rules={DeleteAccountRequest(t).phone_number}
										>
											<PhoneInput
												countrySelectProps={{ unicodeFlags: true }}
												placeholder={t('PHONE_NUMBER')}
												name="phone_number"
												value={phoneNo}
												international
												defaultCountry="IN"
												countryCallingCodeEditable={false}
												onChange={setPhone}
												className={displayOtp ? 'disabled-email-field delete-phone-input' : 'delete-email-input'}
												disabled={displayOtp}
											/>
										</Form.Item></>
								}

							</div>
							{
								displayOtp && <div className="field otp-field">
									<Form.Item name="otp"
										label={t("ENTER_OTP")}
										className="delete-otp-input"
										rules={DeleteAccountRequest(t).otp}
									>
										<OtpInput
											className="otp-input"
											numInputs={4}
											//isInputSecure
											shouldAutoFocus
											inputStyle={{
												display: "flex",
												justifyContent: "space-between",
												border: "1px solid transparent",
												borderRadius: "30px",
												textAlign: "center",
												width: "54px",
												height: "54px",
												margin: "0 10px",
												fontSize: "16px",
												color: "#e26e1e",
												fontWeight: "400",
												caretColor: "blue",
												WebkitTextSecurity: 'square',
											}}
										/>
									</Form.Item>
								</div>
							}
							{!displayOtp && <div className="field delete-account-btn-group">
								<Button
									//loading={saving}
									type="primary"
									size="large"
									className="delete-account-btn"
									onClick={handleSubmit}
								>
									{t('SUBMIT')}
								</Button>
							</div>}
							{displayOtp && <div className="field delete-account-btn-group">
								<Button
									loading={saving}
									type="primary"
									size="large"
									htmlType="submit"
									className="delete-account-btn"
								>
									{t('SUBMIT')}
								</Button>
							</div>}
							{displayOtp && <div className="otp-sent-text">
								<img src={CheckMark} alt="checkmark" />{t("OTP_HAS_BEEN_SENT")}
							</div>}
						</FormBox>

					</div>
				</div>
			</div>
		</section>

	);
});

export default DeleteAccount;
