import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useStore from "../../../store";
import { Button, Tabs } from "antd";
import { GrDocumentExcel } from "react-icons/gr";
import { useHistory } from "react-router";
//import { FiSettings } from "react-icons/fi";
import { TransactionType } from "../../../config/Global";
import MyWalletList from "./MyWalletList";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import AllPermissionList from "../../../config/AllPermissionList";
import ExportPopupComponent from "../../../utils/ExportPopupComponent";
import mobile_money_icon from "../../../assets/images/mobile-dollar-svgrepo-com.svg";

const MyWallet: React.FC = observer(() => {
	const history = useHistory()
	const { t } = useTranslation();
	const { TabPane } = Tabs;
	const [isTransaction, setIsTransaction] = useState<boolean>(false);
	const [userRoleInThisGroup, setUserRoleInThisGroup] = useState<any>();

	const [exportSaving, setExportSaving] = useState<boolean>(false);
	const [exportPopup, showExportPopup] = useState<boolean>(false);

	const goToAddWalletSettings = () => {
		history.push('/wallet-settings');
	}

	const {
		WALLET: { setupMyTransactionListGrid, transactionType, changeTransactionType, exportMyTransactionList, myTransactionListGrid },
		AUTH: { user, selectedGroup, permissions, fetchAuthUser },
	} = useStore();

	const setTransactionType = (key: any) => {
		changeTransactionType('my', key);
	}

	useEffect(() => {
		selectedGroup && user?.groups.forEach((element: any) => {
			if (element?.id == selectedGroup) {
				setUserRoleInThisGroup(element?.role?.name ?? "");
			}
		});
		if (!isTransaction) {
			setTransactionType(0);
			setIsTransaction(true);
		}
	}, [isTransaction, setIsTransaction, setTransactionType, user, userRoleInThisGroup, selectedGroup, permissions]);

	const exportMyTransaction = (type: string) => {
		setExportSaving(true);
		exportMyTransactionList(type).finally(() => {
			setExportSaving(false);
			showExportPopup(false);
		});
	}

	useEffect(() => {
		fetchAuthUser();
	}, [fetchAuthUser]);

	return (
		<>
			<div className="member-details-section text">
				<div className="main grid-class">
					<div className="heading-title-wrap">
						<h3>{t("MY_WALLET")}</h3>
						{user && (
							<small>
								{t("WALLET_BALANCE")} : {user.wallet_balance}
							</small>
						)}
					</div>
					<div className="heading-btn-wrap heading-with-icon">
						{(userRoleInThisGroup && (permissions && permissions.indexOf(AllPermissionList.WALLET_LIST) >= 0)) && (
							<div className="btn">
								<Link className="btn" to="/view-group-wallet" title={t("VIEW_GROUP_TRANSACTIONS")}>
									{t("VIEW_GROUP_TRANSACTIONS")}
								</Link>
							</div>
						)}
						{myTransactionListGrid && myTransactionListGrid[transactionType] && myTransactionListGrid[transactionType].api.getDisplayedRowCount() > 0 && <Button className="btn-yellow" type="primary" onClick={() => showExportPopup(true)}>
							{t("GENERATE_REPORT")} <GrDocumentExcel />
						</Button>}

						<ExportPopupComponent
							callbackExportFunction={exportMyTransaction}
							setVisible={showExportPopup}
							visible={exportPopup}
							saving={exportSaving}
						/>
						<div className="setting">
							<Button
								type="primary"
								title="Settings"
								onClick={goToAddWalletSettings}
							>
								{/* <FiSettings /> */}
								{t("MOBILE_MONEY")}
								<img src={mobile_money_icon} alt="mobile_money_icon"/>

							</Button>
						</div>
					</div>
					<div className="custom-tabs walletsCustomTabs">
						<Tabs
							defaultActiveKey={"0"}
							onChange={setTransactionType}
							style={{ marginBottom: 32 }}
						>
							<TabPane
								tab={t("TRANSACTIONS")}
								key={"0"}
								active={transactionType == "0"}
							>
								<MyWalletList setupGrid={setupMyTransactionListGrid} />
							</TabPane>
							<TabPane
								tab={t("LOANS")}
								key={TransactionType.LOAN}
								active={transactionType == TransactionType.LOAN}
							>
								<MyWalletList setupGrid={setupMyTransactionListGrid} />
							</TabPane>
							<TabPane
								tab={t("FINE")}
								key={TransactionType.FINE}
								active={transactionType == TransactionType.FINE}
							>
								<MyWalletList setupGrid={setupMyTransactionListGrid} />
							</TabPane>
							<TabPane
								tab={t("WELFARE_FUND")}
								key={TransactionType.WELFARE_COLLECTION}
								active={transactionType == TransactionType.WELFARE_COLLECTION}
							>
								<MyWalletList setupGrid={setupMyTransactionListGrid} />
							</TabPane>
						</Tabs>
					</div>
				</div>
			</div>
		</>
	);
});

export default MyWallet;
